export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: NavigationItem[];
}
export const NavigationItems: NavigationItem[] = [
  {
    id: 'navigation',
    title: '',
    type: 'group',
    icon: 'icon-navigation',
    children: [
      {
        id: 'home',
        title: 'Home',
        icon: '../../../../../../assets/images/widget/home.svg',
        type: 'item',
        url: '/dashboard/home'
      },
      {
        id: 'conta',
        title: 'Minha Conta',
        icon: '../../../../../../assets/images/widget/icon-wallet.svg',
        type: 'collapse',
        children: [
          {
            id: 'statementBalance',
            title: 'Saldo e Extrato',
            type: 'item',
            url: '/dashboard/home',
          },
          {
            id: 'subContas',
            title: 'Sub-Contas',
            type: 'item',
            url: '/cadastrar/Filial/sub-account',
          },

        ]
      },
      {
        id: 'pix',
        title: 'Pix',
        icon: '../../../../../../assets/images/widget/pix-icon.svg',
        type: 'collapse',
        children: [
          {
           id: 'transferir',
           title: 'Transferir',
           type: 'item',
           url: '/pix/send',
          },
          {
            id: 'receber-pix',
            title: 'Receber',
            type: 'item',
            url: '/pix/qrcode',
          },
          {
            id: 'minhas-chaves',
            title: 'Minhas Chaves',
            type: 'item',
            url: '/pix/key',
          },
          // {
          //    id: 'limite-pix',
          //    title: 'Limites Pix',
          //    type: 'item',
          //    url: '/pix/limit',
          // },
          {
            id: 'atendimento-pix',
            title: 'Atendimento',
            type: 'item',
            url: '/pix/atendimento',
         },
        ]
      },
      // {
      //   id: 'filial',
      //   title: 'Filiais',
      //   icon: '../../../../../../assets/images/widget/company-icon.svg',
      //   type: 'item',
      //   url: '/cadastrar/Filial/Empresa'
      // },
      // {
      //   id: 'account',
      //   title: 'Conta Pagamentos',
      //   icon: '../../../../../../assets/images/widget/icon-emprestimo.svg',
      //   type: 'item',
      //   url: '/cadastro/Conta/Pagamento'
      // },
      {
         id: 'cliente',
         title: 'Clientes',
         icon: '../../../../../../assets/images/widget/users.svg',
         type: 'item',
         url: '/Cadastrar/Clientes/Empresa'
      },
      {
        id: 'cobrancas',
        classes: 'link-start',
        title: 'Cobranças',
        icon: '../../../../../../assets/images/widget/icon-export.svg',
        type: 'collapse',
        children: [
          {
            id: 'gerar-link',
            title: 'Gerar Links',
            type: 'item',
            url: '/Pagamento/cliente/Link',
          },
          {
            id: 'generate-voucher',
            title: 'Gerar Cupons',
            type: 'item',
            url: '/cupom/list',
          }
        ]
      },
      {
        id: 'boleto',
        classes: 'link-end',
        title: 'Boletos',
        icon: '../../../../../../assets/images/widget/icon-boleto.svg',
        type: 'collapse',
        children: [
          {
            id: 'gerar-boleto',
            title: 'Gerar novo boleto',
            type: 'item',
            url: 'boleto/checkout',
          },
          {
            id: 'meus-boletos',
            title: 'Meus boletos',
            type: 'item',
            url: 'boleto/list',
          }

        ]
      },
      {
        id: 'payment',
        title: 'Pagamentos',
        icon: '../../../../../../assets/images/widget/icon-emprestimo.svg',
        type: 'collapse',
        url: '/dashboard/home',
        children: [
          {
            id: 'pagar-boleto',
            title: 'Boletos',
            type: 'item',
            url: 'boleto/payment',
          },
        ]
      },
      {
        id: 'products',
        classes: 'products',
        title: 'Produtos',
        icon: '../../../../../../assets/images/widget/icon-export.svg',
        type: 'item',
        url: '/Cadastrar/Clientes/Produto'
      },
      {
        id: 'chave',
        classes: 'link-end',
        title: 'Chaves de Acesso',
        icon: '../../../../../../assets/images/widget/key.svg',
        type: 'item',
        url: '/integracao/api/chave'
      },

      {
        id: 'tax',
        classes: 'link-end',
        title: 'Taxas',
        icon: '../../../../../../assets/images/widget/company-icon.svg',
        type: 'collapse',
        children: [
          {
            id: 'baas',
            title: 'Taxas BAAS',
            type: 'item',
            url: '/baas/tax',
          }
        ]
      },
    ]
  }
];
