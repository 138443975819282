// angular import
import { Component, EventEmitter, Output } from '@angular/core';

// project import
import { ThemeConfig } from 'src/app/app-config';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NavLogoComponent } from './nav-logo/nav-logo.component';
import { NavContentComponent } from './nav-content/nav-content.component';
import { ParameterCustomized } from 'src/app/demo/Model/Enum/ParameterCustomized';
import { DomainService } from 'src/app/theme/shared/service/domain.service';

@Component({
  selector: 'app-navigation',
  standalone: true,
  imports: [SharedModule, NavLogoComponent, NavContentComponent],
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  // public props
  @Output() NavCollapse = new EventEmitter();
  @Output() NavCollapsedMob = new EventEmitter();
  navCollapsed;
  navCollapsedMob;
  windowWidth: number;

  backgroundColorHeader = this.domainService.getParameter(ParameterCustomized.LBACKGROUND_COLOR_HEADER).value;

  // constructor
  constructor(private domainService: DomainService) {
    this.windowWidth = window.innerWidth;
    this.navCollapsed = this.windowWidth >= 992 ? ThemeConfig.isCollapseMenu : false;
    this.navCollapsedMob = false;
  }

  // public method
  navCollapse() {
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.NavCollapse.emit();
    }
  }

  navCollapseMob() {
    if (this.windowWidth < 992) {
      this.NavCollapsedMob.emit();
    }
  }
}
