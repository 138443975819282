import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customized } from 'src/app/demo/Model/Customized';
import { Parameter } from 'src/app/demo/Model/Parameter';

@Injectable({
  providedIn: 'root'
})
export class DomainService {
  httpOptions = {  headers: new HttpHeaders({  'Content-Type': 'application/json; charset=UTF-8', }) };
  private apiUrl = '/management-business/v1/domain/parameter?domain=';

  constructor(private http: HttpClient) { }

  getParameterCustomized(domain: any): Promise<Customized> {
    const url = this.apiUrl + domain
    return this.http.get<Customized>(url, this.httpOptions).toPromise();
  }

  getParameter(type: String): Parameter {
    const customized: Customized = JSON.parse(sessionStorage.getItem('customized')!);
    if(customized.listParameter.length > 0){
      const item = customized.listParameter.find(param => param.code.trim().toUpperCase() === type.trim().toUpperCase());
      return item;
    }
    return null;
  }

  getCustomized(): Customized {
    const customized: Customized = JSON.parse(sessionStorage.getItem('customized')!);
    return customized
  }
}
