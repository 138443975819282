// angular import
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// project import
import { AdminComponent } from './theme/layout/admin/admin.component';
import { GuestComponent } from './theme/layout/guest/guest.component';
import { AuthGuard } from './theme/shared/_helpers/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/dashboard/home',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./demo/dashboard/dashboard.module').then((m) => m.DashboardModule)
      },
      {
        path: 'settings',
        loadComponent: () => import('./demo/dashboard/settings-profile/settings-profile.component').then(m => m.SettingsProfileComponent)
      },

      {
        path: 'cadastrar',
        loadChildren: () => import('./demo/pages/branch/adm.module').then((m) => m.AdmModule)
      },
      {
        path: 'cadastro',
        loadChildren: () => import('./demo/pages/profile-settings/setting.module').then((m) => m.SettingModule)
      },
      {
        path: 'Cadastrar',
        loadChildren: () => import('./demo/pages/business/business-routing.module').then((m) => m.BusinessRoutingModule)
      },
      {
        path: 'Pagamento',
        loadChildren: () => import('./demo/pages/linkPayment/linkPayment-routing.module').then((m) => m.LinkPaymentRoutingModule)
      },
      {
        path: 'integracao',
        loadChildren: () => import('./demo/pages/integration/integration-routing.module').then((m) => m.IntegrationRoutingModule)
      },
      {
        path: 'boleto',
        loadChildren: () => import('./demo/pages/payment/boleto-module').then((m) => m.BoletoModule)
      },
      {
        path: 'cupom',
        loadChildren: () => import('./demo/pages/payment/cupom-module').then((m) => m.CupomModule)
      },      
      {
        path: 'pix',
        loadChildren: () => import('./demo/pages/pix/PixModule').then((m) => m.PixModule)
      },
      {
        path: 'baas',
        loadChildren: () => import('./demo/pages/tax-settings/baas-settings-routing.module').then((m) => m.baasSettingsRoutingModule)
      }
    ]
  },


  {
    path: '',
    component: GuestComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./demo/pages/authentication/authentication.module').then((m) => m.AuthenticationModule)
      },
      {
        path: 'demonstration',
        loadChildren: () => import('./demo/pages/payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: 'link',
        loadChildren: () => import('./demo/pages/payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: '**',
        component: GuestComponent,
        loadComponent: () => import('./demo/pages/maintenance/error/error.component').then((c) => c.ErrorComponent)
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
