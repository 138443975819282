// angular import
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ParameterCustomized } from 'src/app/demo/Model/Enum/ParameterCustomized';
import { DomainService } from 'src/app/theme/shared/service/domain.service';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';

@Component({
  selector: 'app-nav-logo',
  standalone: true,
  imports: [SharedModule, RouterModule],
  templateUrl: './nav-logo.component.html',
  styleUrls: ['./nav-logo.component.scss']
})
export class NavLogoComponent {
  // public props
  @Input() navCollapsed: boolean;
  @Output() NavCollapse = new EventEmitter();
  windowWidth = window.innerWidth;

  imgHeader = this.domainService.getParameter(ParameterCustomized.LOGO_HEADER).value;
  LogoWidthHeader  = this.domainService.getParameter(ParameterCustomized.LOGO_HEADER).width;
  // constructor
  constructor(private domainService: DomainService) { }

  ngOnInit() {

  }

  // public method
  navCollapse() {
    if (this.windowWidth >= 992) {
      this.navCollapsed = !this.navCollapsed;
      this.NavCollapse.emit();
    }
  }
}
