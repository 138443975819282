@if (!item.hidden) {
  <li class="nav-item pcoded-menu-caption">
    <label for="title">{{ item.title | translate }}</label>
  </li>
  @for (item of item.children; track item) {
    @if (item.type === 'collapse') {
      <app-nav-collapse [item]="item" />
    }
    @if (item.type === 'item') {
      <app-nav-item [item]="item" />
    }
  }
}
<div class="text-container" style="margin-bottom: 100px;">
  <h2 class="text-navbar" (click)="goToSettings()"> <img class="icon" src="../../../../../../../assets/images/widget/config-icon.svg" alt=""> Configurações</h2>
  <h2 class="text-navbar" (click)="logout()"> <img class="icon" src="../../../../../../../assets/images/widget/icon-logout.svg" alt=""> Sair</h2>  
</div>
